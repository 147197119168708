<style>
body.scroll-down{
  margin-top: 200px;
}
</style>
<template>
    <div id="illness-payment" class="pt-5 questions" style="min-height: 95vh;">
        <div class="container">
            <div class="row p-25vh" id="detail-postcode">
                <div class="col-12">
                    <h2 class="h5 text-center mb-3 text-default">{{$t("postcode")}}</h2>
                    <div class="form-group">
                        <input type="tel" class="form-control input-lg" data-selenium-name="postalCode" v-bind:class="[isValid.postcode ? 'text-center' : 'to2do']"  maxlength="5" id="postcode" v-model.trim="Global.phaccount.postalCode" autocomplete="on" spellcheck="false">

                        <span class="glyphicons glyphicons-validate" v-if="Global.phaccount.postalCode.length == 5" v-bind:class="giconsValidate( isValid.postcode )"></span>
                    </div>
                    <div class="text-center" v-show="isValid.postcode && !stepCompleted('detail-postcode')">
                        <button class="btn btn-next btn-lg" data-selenium-name="postalCode-next" @click="completeQuestion().postcode()">{{$t("next")}}</button>
                    </div>
                    <div class="orange-text text-center" v-if="!isValid.postcode && Global.phaccount.postalCode.length === 5">
                      <span class="glyphicons glyphicons-validate glyphicons-warning-sign animated swing"></span> {{$t("pleaseInsertAZipCode")}}
                    </div>
                </div>
            </div>
            <div class="row p-25vh" id="detail-address" v-show="stepCompleted('detail-postcode', true)">
                <div class="col-12 mb-5" id="detail-address-province">
                    <h2 class="h5 text-center mb-3 text-default">{{$t("province")}}</h2>

                    <div class="select-option dropdown mb-3">
                        <button id="detail-province-btn" data-selenium-name="selected-province" :data-selenium-value="Global.phaccount.province" class="btn btn-block btn-lg select-btn" v-bind:class="{'selected' : Global.phaccount.province}"  type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{Object.entries(Global.province).length > 0 ? (['id', 'en'].includes(Global.quote.prefLang) ? Global.province.name[0] : Global.province.name[1]) : $t("select")}}
                            <div class="btn-arrow"><svg fill="#ff5011" height="12" width="12" viewBox="0 0 24 24" role="img" class="pull-right"><path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path></svg></div>
                        </button>

                        <div id="detail-province-options" aria-labelledby="detail-province-btn" class="dropdown-menu">
                            <a v-for="(province, index) in Global.provinces" v-bind:key="'privince-' + index" data-selenium-name="province" :data-selenium-value="province.name[0]" class="dropdown-item" href="javascript:void(0);" @click="Global.province = province">{{['id', 'en'].includes(Global.quote.prefLang) ? province.name[0] : province.name[1]}}</a>
                        </div>
                    </div>
                </div>

                <div class="col-12 mb-5" id="detail-address-district" v-show="stepCompleted('detail-address-province', true)">
                    <h2 class="h5 text-center mb-3 text-default">{{$t("district")}}</h2>

                    <div class="select-option dropdown mb-3">
                        <button id="detail-district-btn" data-selenium-name="selected-district" :data-selenium-value="Global.phaccount.district" class="btn btn-block btn-lg select-btn" v-bind:class="{'selected' : Global.phaccount.district}" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{Object.entries(Global.district).length > 0 ? (['id', 'en'].includes(Global.quote.prefLang) ? Global.district.name[0] : Global.district.name[1]) : $t("select")}}
                            <div class="btn-arrow"><svg fill="#ff5011" height="12" width="12" viewBox="0 0 24 24" role="img" class="pull-right"><path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path></svg></div>
                        </button>

                        <div id="detail-district-options" aria-labelledby="detail-district-btn" class="dropdown-menu">
                            <a v-for="(district, index) in Global.districts" v-bind:key="'district-' + index" data-selenium-name="district" :data-selenium-value="district.name[0]" class="dropdown-item" href="javascript:void(0);" @click="Global.district = district">{{['id', 'en'].includes(Global.quote.prefLang) ? district.name[0] : district.name[1]}}</a>
                        </div>
                    </div>
                </div>

                <div class="col-12 mb-5" id="detail-address-subdistrict" v-show="stepCompleted('detail-address-district', true)">
                    <h2 class="h5 text-center mb-3 text-default">{{$t("subdistrict")}}</h2>

                    <div class="select-option dropdown mb-3">
                        <button id="detail-subdistrict-btn" data-selenium-name="selected-suddistrict" :data-selenium-value="Global.phaccount.subDistrict" class="btn btn-block btn-lg select-btn" v-bind:class="{'selected' : Global.phaccount.subDistrict}" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{Object.entries(Global.subDistrict).length > 0 ? (['id', 'en'].includes(Global.quote.prefLang) ? Global.subDistrict.name[0] : Global.subDistrict.name[1]) : $t("select")}}
                            <div class="btn-arrow"><svg fill="#ff5011" height="12" width="12" viewBox="0 0 24 24" role="img" class="pull-right"><path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path></svg></div>
                        </button>

                        <div id="detail-subdistrict-options" aria-labelledby="detail-subdistrict-btn" class="dropdown-menu">
                            <a v-for="(subDistrict, index) in Global.subDistrictsList" v-bind:key="'suddistrict-' + index" data-selenium-name="suddistrict" :data-selenium-value="subDistrict.name[0]" class="dropdown-item" href="javascript:void(0);"  @click="Global.subDistrict = subDistrict">{{['id', 'en'].includes(Global.quote.prefLang) ? subDistrict.name[0] : subDistrict.name[1]}}</a>
                        </div>
                    </div>
                </div>

                <div class="col-12 mb-5" id="detail-address-roadsoi" v-show="stepCompleted('detail-address-subdistrict', true)">
                    <h2 class="h5 text-center mb-3 text-default">{{$t("road")}}</h2>
                    <div class="form-group">
                        <input type="text" class="form-control input-lg text-center" data-selenium-name="roadsoi" id="roadsoi" v-model.trim="Global.phaccount.soi"  v-on:keyup.enter="completeQuestion().roadsoi()">
                        <span class="glyphicons glyphicons-validate" v-if="Global.phaccount.soi" v-bind:class="giconsValidate( Global.phaccount.soi )"></span>
                    </div>
                    <div class="text-center" v-if="!stepCompleted('detail-address-roadsoi', true)">
                        <button class="btn btn-next btn-lg" data-selenium-name="roadsoi-next" @click="completeQuestion().roadsoi()">{{$t("next")}}</button>
                    </div>
                </div>

                <div class="col-12 mb-5" id="detail-address-village" v-show="stepCompleted('detail-address-roadsoi', true)">
                    <h2 class="h5 text-center mb-3 text-default">{{$t("village")}}</h2>
                    <div class="form-group">
                        <input type="text" class="form-control input-lg" data-selenium-name="village" v-bind:class="[Global.phaccount.village ? 'text-center' : 'to2do']" id="village" v-model.trim="Global.phaccount.village" v-on:keyup.enter="completeQuestion().village()">
                        <span class="glyphicons glyphicons-validate" v-if="Global.phaccount.village" v-bind:class="giconsValidate( Global.phaccount.village )"></span>
                    </div>
                    <div class="text-center" v-if="Global.phaccount.village && !stepCompleted('detail-address-village', true)">
                        <button class="btn btn-next btn-lg" data-selenium-name="village-next"  @click="completeQuestion().village()">{{$t("next")}}</button>
                    </div>
                </div>

                <div class="col-12 mb-5" id="detail-address-houseNumber" v-show="stepCompleted('detail-address-village', true)">
                    <h2 class="h5 text-center mb-3 text-default">{{$t("houseno")}}</h2>
                    <div class="form-group">
                        <input type="text" class="form-control input-lg" data-selenium-name="houseNumber" v-bind:class="[Global.phaccount.houseNumber ? 'text-center' : 'to2do']" id="houseNumber" v-model.trim="Global.phaccount.houseNumber" v-on:keyup.enter="completeQuestion().houseNumber()">
                        <span class="glyphicons glyphicons-validate" v-if="Global.phaccount.houseNumber" v-bind:class="giconsValidate( Global.phaccount.houseNumber )"></span>
                    </div>
                    <div class="text-center" v-if="Global.phaccount.houseNumber && !stepCompleted('detail-address-houseNumber', true)">
                        <button class="btn btn-next btn-lg" data-selenium-name="houseNumber-next" @click="completeQuestion().houseNumber()">{{$t("next")}}</button>
                    </div>
                </div>

                <div class="col-12 mb-5" id="detail-address-moo" v-show="stepCompleted('detail-address-houseNumber', true)" v-if="false">
                    <h2 class="h5 text-center mb-3 text-default">{{$t("moo")}}</h2>
                    <div class="form-group">
                        <input type="text" class="form-control input-lg" id="moo" v-bind:class="[Global.phaccount.moo ? 'text-center' : 'to2do']" v-model.trim="Global.phaccount.moo" v-on:keyup.enter="completeQuestion().moo()">
                        <span class="glyphicons glyphicons-validate" v-if="Global.phaccount.moo" v-bind:class="giconsValidate( Global.phaccount.moo )"></span>
                    </div>
                    <div class="text-center" v-if="Global.phaccount.moo && !stepCompleted('detail-address-moo', true)">
                        <button class="btn btn-next btn-lg" @click="completeQuestion().moo()">{{$t("next")}}</button>
                    </div>
                </div>
            </div>

            <div class="row p-25vh" id="detail-beneficiary" v-show="stepCompleted('detail-address', true)">
                <div class="col-12 mb-5">
                    <h2 class="h5 text-center mb-3 text-default">{{$t("beneficiary.title")}}</h2>

                    <div class="accordion" id="accordionBeneficiary">
                        <div class="card" v-for="(item, index) in Global.beneficiary" v-bind:key="'beneficiary-' + index">
    			            <div class="card-header" :id="'heading-' + index">
                              <button type="button" class="btn btn-collapse btn-Beneficiary btn-block collapsed" data-selenium-name="beneficiary-btn" :data-selenium-value="index" data-toggle="collapse" :data-target="'#collapse-' + index" aria-expanded="false" :aria-controls="'collapse-' + index">
                                    <p class="m-0 d-inline-block">{{item.firstName}} {{item.lastName}} / {{item.percentage}}%</p>
                                    <svg style="float-right" viewBox="0 0 24 24" width="24" height="24" stroke="#90A4AE" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="6 9 12 15 18 9"></polyline></svg>
                                </button>
    			            </div>

                            <div :id="'collapse-' + index" :data-selenium-name="'beneficiary-' + index" class="collapse" aria-labelledby="headingOne" data-parent="#accordionBeneficiary">
                                <div class="card-body">
                                    <div class="form-group">
                                        <label for="Beneficiary-first-name-1">{{$t("beneficiary.firstName")}}*</label>
                                        <input type="text" class="form-control" data-selenium-name="firstName" :id="'beneficiary-firstName' + index" v-model.trim="item.firstName">
                                    </div>

                                    <div class="form-group">
                                        <label for="Beneficiary-last-name-1">{{$t("beneficiary.lastName")}}*</label>
                                        <input type="text" class="form-control" data-selenium-name="lastName" :id="'beneficiary-lastName' + index" v-model.trim="item.lastName">
                                    </div>

                                    <div class="form-group">
                                        <label for="Relationship-plan">{{$t("beneficiary.relationship")}}</label>
                                        <select for="Relationship-plan" class="custom-select" data-selenium-name="relationship" :data-selenium-value="item.relationship" v-model="item.relationship">
                                            <option value="Spouse">{{$t("beneficiary.relation.spouse")}}</option>
                                            <option value="Father">{{$t("beneficiary.relation.father")}}</option>
                                            <option value="Mother">{{$t("beneficiary.relation.mother")}}</option>
                                            <option value="Son / Daughter">{{$t("beneficiary.relation.son")}}</option>
                                            <option value="Relative">{{$t("beneficiary.relation.relative")}}</option>
                                            <option value="Legal heir">{{$t("beneficiary.relation.legal_heir")}}</option>
                                            <option value="Other">{{$t("beneficiary.relation.other")}}</option>
                                        </select>

                                    </div>

                                    <div class="form-group">
                                        <label for="Percentage-1">{{$t("beneficiary.percentage")}}</label>
                                        <select id="Percentage-1" class="custom-select" v-model="item.percentage" data-selenium-name="percentage" :data-selenium-value="item.percentage">
                                            <option value="10" :disabled="!isBeneficiaryPercentageSelectable(10, item.percentage)">10</option>
                                            <option value="20" :disabled="!isBeneficiaryPercentageSelectable(20, item.percentage)">20</option>
                                            <option value="30" :disabled="!isBeneficiaryPercentageSelectable(30, item.percentage)">30</option>
                                            <option value="40" :disabled="!isBeneficiaryPercentageSelectable(40, item.percentage)">40</option>
                                            <option value="50" :disabled="!isBeneficiaryPercentageSelectable(50, item.percentage)">50</option>
                                            <option value="60" :disabled="!isBeneficiaryPercentageSelectable(60, item.percentage)">60</option>
                                            <option value="70" :disabled="!isBeneficiaryPercentageSelectable(70, item.percentage)">70</option>
                                            <option value="80" :disabled="!isBeneficiaryPercentageSelectable(80, item.percentage)">80</option>
                                            <option value="90" :disabled="!isBeneficiaryPercentageSelectable(90, item.percentage)">90</option>
                                            <option value="100" :disabled="!isBeneficiaryPercentageSelectable(100, item.percentage)">100</option>
                                        </select>
                                    </div>

                                    <div class="text-center">
                                        <button type="button" data-selenium-name="removeBeneficiary" :data-selenium-value="index" class="btn btn-danger btn-block bg-white text-danger border-0" @click="removeBeneficiary(index)">x Remove</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 mb-3" v-if="isAddingBeneficiary" id="beneficiary-form">
                    <div class="card">
                        <div class="card-body">
                            <p class="text-center h5">
                              <i18n-t tag="span" keypath="beneficiary.no">
                                {{Global.beneficiary.length + 1}}
                              </i18n-t>
                            </p>

                            <div class="form-group">
                                <label for="Beneficiary-first-name-2">{{$t("beneficiary.firstName")}}*</label>
                                <input type="text" data-selenium-name="beneficiary-firstName" class="form-control" id="Beneficiary-first-name-2" v-model.trim="beneficiary.firstName">
                                <span class="glyphicons glyphicons-validate top-label" v-if="beneficiary.firstName.length > 1" v-bind:class="giconsValidate( beneficiary.firstName.length > 1 )"></span>
                            </div>

                            <div class="form-group">
                                <label for="Beneficiary-last-name-2">{{$t("beneficiary.lastName")}}*</label>
                                <input type="text" data-selenium-name="beneficiary-lastName" class="form-control" id="Beneficiary-last-name-2" v-model.trim="beneficiary.lastName">
                                <span class="glyphicons glyphicons-validate top-label" v-if="beneficiary.lastName.length > 1" v-bind:class="giconsValidate( beneficiary.lastName.length > 1 )"></span>
                            </div>

                            <div class="form-group">
                                <label for="Relationship-plan">{{$t("beneficiary.relationship")}}</label>
                                <select for="Relationship-plan" class="custom-select" v-model="beneficiary.relationship" data-selenium-name="beneficiary-relationship" :data-selenium-value="beneficiary.relationship">
                                    <option value="">{{$t("select")}}</option>
                                    <option value="Spouse">{{$t("beneficiary.relation.spouse")}}</option>
                                    <option value="Father">{{$t("beneficiary.relation.father")}}</option>
                                    <option value="Mother">{{$t("beneficiary.relation.mother")}}</option>
                                    <option value="Son / Daughter">{{$t("beneficiary.relation.son")}}</option>
                                    <option value="Relative">{{$t("beneficiary.relation.relative")}}</option>
                                    <option value="Legal heir">{{$t("beneficiary.relation.legal_heir")}}</option>
                                    <option value="Other">{{$t("beneficiary.relation.other")}}</option>
                                </select>

                            </div>

                            <div class="form-group">
                                <label for="Percentage-1">{{$t("beneficiary.percentage")}}</label>
                                <select id="Percentage-1" class="custom-select" v-model="beneficiary.percentage" data-selenium-name="beneficiary-percentage" :data-selenium-value="beneficiary.percentage">
                                    <option value="">{{$t("select")}}</option>
                                    <option value="10" :disabled="10 > availableBeneficiaryPercentage">10</option>
                                    <option value="20" :disabled="20 > availableBeneficiaryPercentage">20</option>
                                    <option value="30" :disabled="30 > availableBeneficiaryPercentage">30</option>
                                    <option value="40" :disabled="40 > availableBeneficiaryPercentage">40</option>
                                    <option value="50" :disabled="50 > availableBeneficiaryPercentage">50</option>
                                    <option value="60" :disabled="60 > availableBeneficiaryPercentage">60</option>
                                    <option value="70" :disabled="70 > availableBeneficiaryPercentage">70</option>
                                    <option value="80" :disabled="80 > availableBeneficiaryPercentage">80</option>
                                    <option value="90" :disabled="90 > availableBeneficiaryPercentage">90</option>
                                    <option value="100" :disabled="100 > availableBeneficiaryPercentage">100</option>
                                </select>
                            </div>

                            <div class="text-center">
                                <div class="btn-group my-3" role="group" aria-label="">
                                    <button type="button" id="btn-cancel-third-1" data-selenium-name="beneficiary-cancel" class="btn btn-outline-secondary mr-2 px-lg-5 px-4"  @click="cancelBeneficiary()" >{{$t("cancel")}}</button>
                                    <button type="button" id="btn-update-third-1" data-selenium-name="beneficiary-confirm" class="btn btn-primary strong px-4 px-lg-5" :disabled="!isValidBeneficiary" @click="confirmBeneficiary()">{{$t("confirm")}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 mb-5" v-if="!isAddingBeneficiary && Global.beneficiary.length < 3">
                    <div class="text-center">
                        <button class="btn btn-next mt-3" data-selenium-name="addBeneficiary" type="button"  @click="addBeneficiary()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            {{$t("beneficiary.add")}}
                        </button>
                    </div>
                </div>

                <div class="col-12" v-if="Global.beneficiary.length <= 3 && availableBeneficiaryPercentage > 0">
                  <div class="text-center">{{$t("beneficiaryDecs")}}</div>
                </div>
            </div>

            <div class="row p-25vh" id="detail-continue" v-show="stepCompleted('detail-address', true) && availableBeneficiaryPercentage < 1">
                <div class="col-12 col-lg-9 col-md-10 mx-auto text-center">
                    <button class="btn btn-lg btn-block btn-link-default buy-now py-0" data-selenium-name="go2review" @click="go2review()">
                        <span id="personal2button1">{{$t("continue")}}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="p-25vh">&nbsp;</div>
    </div>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";

export default {
  name: "PolicyDetails2",
  beforeRouteLeave (to, from, next) {
    if(window.pageYOffset > 0) {
      this.scrollToTopPage()
      next(false)
    } else next()
  },
  mixins: [Mixin],
  data() {
    return {
      Global,
      beneficiary: {
        firstName: "",
        lastName: "",
        relationship: "",
        percentage: ""
      },
      isAddingBeneficiary: false,
      isValid: {
        postcode: false
      }
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    $(window).off("scroll");
    $(window).on("scroll", function() {
      if ($(window).scrollTop() > 199) { 
        $("nav").addClass("fixed-menu");
        $("body").addClass("scroll-down");
      } else {
        $("nav").removeClass("fixed-menu");
        $("body").removeClass("scroll-down");
      }
    });

    if (
      (Global.provinces.length === 0 ||
        Global.districts.length === 0 ||
        Global.subDistricts.length === 0) &&
      Global.phaccount.postalCode.length == 5
    ) {
      this.getProvince();
    }
  },
  watch: {
    "Global.phaccount.postalCode": function(value) {
      Global.province = {};
      Global.district = {};
      Global.subDistrict = {};
      Global.provinces = [];
      Global.districts = [];
      Global.subDistricts = [];
      Global.subDistrictsList = [];

      if (value.replace(/[^0-9]/g, "").length === 5) {
        this.getProvince();
      }
    },
    "Global.provinces": function(value) {
      var foundProvince = value.find(
        province => province.id == Global.phaccount.provinceCode
      );
      if (foundProvince !== undefined) {
        Global.province = foundProvince;
      }
    },
    "Global.province": function(value) {
      Global.phaccount.provinceCode = "";
      Global.phaccount.province = "";
      if (value.id && value.name && Global.district) {
        try {
          Global.phaccount.provinceCode = value.id;
          Global.phaccount.province = value.name[0];
          this.completeQuestion().province();

          var foundDistrict = Global.districts.find(
              district => district.id == Global.phaccount.districtCode
          );
          if (foundDistrict !== undefined) {
            Global.district = foundDistrict;
          }
        } catch (error) {
          Global.phaccount.provinceCode = "";
          Global.phaccount.province = "";
        }
      }
    },
    "Global.district": function(value) {
      Global.phaccount.districtCode = "";
      Global.phaccount.district = "";
      if (value.id && value.name && Global.subDistricts) {
        try {
          Global.phaccount.districtCode = value.id;
          Global.phaccount.district = value.name[0];
          this.completeQuestion().district();

          var foundSubDistrictsList = Global.subDistricts.find(
              item => item.districtCode == Global.phaccount.districtCode
          );

          if (foundSubDistrictsList !== undefined) {
            Global.subDistrictsList = foundSubDistrictsList.list;

            var foundSubDistrict = Global.subDistrictsList.find(
                subDistrict => subDistrict.id == Global.phaccount.subDistrictCode
            );

            if (foundSubDistrict !== undefined) {
              Global.subDistrict = foundSubDistrict;
            }
          }
        } catch (error) {
          Global.phaccount.districtCode = "";
          Global.phaccount.district = "";
        }
      }
    },
    "Global.subDistrict": function(value) {
      Global.phaccount.subDistrictCode = "";
      Global.phaccount.subDistrict = "";
      if (value.id && value.name) {
        try {
          Global.phaccount.subDistrictCode = value.id;
          Global.phaccount.subDistrict = value.name[0];
          this.completeQuestion().subdistrict();
        } catch (error) {
          Global.phaccount.subDistrictCode = "";
          Global.phaccount.subDistrict = "";
        }
      }
    }
  },
  methods: {
    go2review: function() {
      var vm = this;

      Global.quote.onlineStage = "Submit";
      if (Global.paymentCompleted === true) {
        Global.quote.isActivatePolicy = true;
      }
      this.saveQuote();

      this.loading("show", 3, function() {
        vm.$router.push(Global.productConfig.route.review);
      });
    },
    addBeneficiary: function() {
      if (Global.beneficiary.length > 3) {
        return false;
      }

      this.isAddingBeneficiary = true;
      this.moveTo("#beneficiary-form");
    },
    cancelBeneficiary: function() {
      this.beneficiary = {
        firstName: "",
        lastName: "",
        relationship: "",
        percentage: ""
      };

      this.isAddingBeneficiary = false;
      this.moveTo("#detail-beneficiary");
    },
    confirmBeneficiary: function() {
      if (!this.isValidBeneficiary) {
        return false;
      }

      Global.beneficiary.push(this.beneficiary);
      this.beneficiary = {
        firstName: "",
        lastName: "",
        relationship: "",
        percentage: ""
      };

      this.isAddingBeneficiary = false;
      this.moveTo("#detail-beneficiary");
    },
    removeBeneficiary: function(index) {
      if (Global.beneficiary[index]) {
        Global.beneficiary.splice(index, 1);
        this.moveTo("#detail-beneficiary");
      }
    },
    completeQuestion: function() {
      var methods = {},
        vm = this;

      function closeDropdown() {
        $(".dropdown-menu").removeClass("show");
        $('[data-toggle="dropdown"]').attr("aria-expanded", false);
      }

      methods.postcode = function() {
        vm.pushStep("detail-postcode");

        if (!Global.phaccount.province) {
          vm.moveTo("#detail-address", function() {
            $("#detail-province-options").addClass("show");
            $("#detail-province-btn").attr("aria-expanded", true);
          });

          return true;
        }

        vm.pushStep("detail-address-province");
        if (!Global.phaccount.district) {
          vm.moveTo("#detail-address-district", function() {
            $("#detail-district-options").addClass("show");
            $("#detail-district-btn").attr("aria-expanded", true);
          });

          return true;
        }

        vm.pushStep("detail-address-district");
        if (!Global.phaccount.subDistrict) {
          vm.moveTo("#detail-address-subdistrict", function() {
            $("#detail-subdistrict-options").addClass("show");
            $("#detail-subdistrict-btn").attr("aria-expanded", true);
          });

          return true;
        }

        vm.pushStep("detail-address-subdistrict");
        vm.moveTo("#detail-address-roadsoi", function() {
          $("#roadsoi").focus();
        });
      };

      methods.province = function() {
        vm.pushStep("detail-address-province");

        closeDropdown();

        vm.moveTo("#detail-address-district", function() {
          $("#detail-district-options").addClass("show");
          $("#detail-district-btn").attr("aria-expanded", true);
        });
      };

      methods.district = function() {
        vm.pushStep("detail-address-district");

        closeDropdown();

        vm.moveTo("#detail-address-subdistrict", function() {
          $("#detail-subdistrict-options").addClass("show");
          $("#detail-subdistrict-btn").attr("aria-expanded", true);
        });
      };

      methods.subdistrict = function() {
        vm.pushStep("detail-address-subdistrict");

        closeDropdown();

        vm.moveTo("#detail-address-roadsoi", function() {
          $("#roadsoi").focus();
        });
      };

      methods.roadsoi = function() {
        vm.pushStep("detail-address-roadsoi");
        vm.moveTo("#detail-address-village", function() {
          $("#village").focus();
        });
      };

      methods.village = function() {
        if (!Global.phaccount.village) {
          return false;
        }

        vm.pushStep("detail-address-village");
        vm.moveTo("#detail-address-houseNumber", function() {
          $("#houseNumber").focus();
        });
      };

      methods.houseNumber = function() {
        if (!Global.phaccount.houseNumber) {
          return false;
        }

        /*
        vm.pushStep("detail-address-houseNumber");
        vm.moveTo("#detail-address-moo", function() {
          $("#moo").focus();
        });
        */

        vm.pushStep("detail-address");
        vm.pushStep("detail-address-houseNumber");
        vm.moveTo("#detail-beneficiary");
      };

      methods.moo = function() {
        if (!Global.phaccount.moo) {
          return false;
        }

        vm.pushStep("detail-address");
        vm.pushStep("detail-address-moo");
        vm.moveTo("#detail-beneficiary");
      };

      //insured

      return methods;
    },
    isBeneficiaryPercentageSelectable: function(percent, selectedPercentage) {
      var avaible =
        this.availableBeneficiaryPercentage + parseInt(selectedPercentage);
      if (percent <= avaible) {
        return true;
      }

      return false;
    },
    getProvince: async function() {
      try {
        this.loading("show");
        var respose = await this.request().get(
          Global.restServer.url +
            "/postals?search=province&keyword=" +
            Global.phaccount.postalCode
        );

        this.loading("hide");
        if (respose.data?.model?.found) {
          Global.provinces = respose.data.model.provinces;
          Global.districts = respose.data.model.districts;
          Global.subDistricts = respose.data.model.subDistricts;

          this.completeQuestion().postcode();
          this.isValid.postcode = true;
        } else {
          this.isValid.postcode = false;
        }
      } catch (error) {
        if(error.response){
            vm.frontEndLog("postals","search=province&keyword=" + Global.phaccount.postalCode, error.response.status.toString())
        }
        this.loading("hide");
        this.isValid.postcode = false;
        console.error("getProvince => ", error);
        
      }
    }
  },
  computed: {
    isValidBeneficiary: function() {
      for (var i in this.beneficiary) {
        if (!this.beneficiary[i]) {
          return false;
        }
      }

      return true;
    },
    availableBeneficiaryPercentage: function() {
      var initialValue = 0;
      var percent = Global.beneficiary.reduce(function(accumulator, item) {
        return accumulator + parseInt(item.percentage);
      }, initialValue);

      return 100 - percent;
    },
    getSubDistricts: function() {
      if (
        !Array.isArray(this.subDistrictModel) ||
        this.subDistrictModel.length < 1 ||
        !Global.phaccount.districtCode
      ) {
        return [];
      }

      var list = this.subDistrictModel.filter(function(item) {
        return item.districtCode == Global.phaccount.districtCode;
      })[0]?.list;
      return !list ? [] : list;
    }
  }
};
</script>
<i18n>
{
  "en": {
    "postcode": "Postcode",
    "province": "Province",
    "district": "District",
    "subdistrict": "Subdistrict",
    "road": "Road/soi",
    "village": "Village/building",
    "houseno": "House number/building number - Moo.",
    "moo": "Moo.",
    "beneficiary": {
      "title": "Beneficiary",
      "no": "Beneficiary No. {0}",
      "firstName": "First Name",
      "lastName": "Last Name",
      "relationship": "Relationship",
      "relation": {
        "spouse": "Spouse",
        "father": "Father",
        "mother": "Mother",
        "son": "Son / Daughter",
        "relative": "Relative",
        "legal_heir": "Legal heir",
        "other": "Other"
      },
      "percentage": "Percentage",
      "add": "Add Beneficiary"
    },
    "beneficiaryDecs":"ร้อยละของผลประโยชน์ต้องรวมกันได้ 100%"
  },
  "id": {
    "postcode": "Postcode",
    "province": "Province",
    "district": "District",
    "subdistrict": "Subdistrict",
    "road": "Road/soi",
    "village": "Village/building",
    "houseno": "House number/building number - Moo.",
    "moo": "Moo.",
    "beneficiary": {
      "title": "Beneficiary",
      "no": "Beneficiary No. {0}",
      "firstName": "First Name",
      "lastName": "Last Name",
      "relationship": "Relationship",
      "relation": {
        "spouse": "Spouse",
        "father": "Father",
        "mother": "Mother",
        "son": "Son / Daughter",
        "relative": "Relative",
        "legal_heir": "Legal heir",
        "other": "Other"
      },
      "percentage": "Percentage",
      "add": "Add Beneficiary"
    },
    "beneficiaryDecs":"Persentase gabungan harus 100%"
  },
}
</i18n>